<template>
	<read-document />
</template>
<script>
import ReadDocument from '@/components/agreement/readDocumentByLegal/ReadDocument.vue';

export default {
	name: 'SupplierDocumentView',
	components: { ReadDocument },
};
</script>
